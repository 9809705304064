import * as SeparatorPrimitive from '@radix-ui/react-separator';
import { styled } from 'styled-system/jsx'

export const Separator = styled(SeparatorPrimitive.Root, {
    base: {
        backgroundColor: "$outline",
        "&[data-orientation=horizontal]": {
            height: 1,
            width: "100%"
        },
        "&[data-orientation=vertical]": {
            height: "100%",
            width: 1
        }
    }
})