import { Label } from "@radix-ui/react-label";
import { styled } from 'styled-system/jsx'

const InputLabel = styled(Label, {
    base: {
        fontSize: "1.17em",
        padding: 2
    }
})

export default InputLabel