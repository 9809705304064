import { styled } from 'styled-system/jsx';
import { Cross2Icon } from '@radix-ui/react-icons';
import * as PopoverPrimitive from '@radix-ui/react-popover';
import { ComponentProps, ReactNode } from 'react';

type Align = "center" | "end" | "start" | undefined

export type PopoverContentProps = ComponentProps<typeof PopoverContent>
const Popover = ({ children, trigger, align, open, ...props }: { children: ReactNode, trigger: JSX.Element, align?: Align } & PopoverContentProps) => {
    return (
        <PopoverRoot open={open}>
            <PopoverTrigger asChild>
                {trigger}
            </PopoverTrigger>
            <PopoverContent collisionPadding={12} align={align} sideOffset={5} {...props} >
                {children}
                <PopoverClose aria-label="Close">
                    <Cross2Icon />
                </PopoverClose>
            </PopoverContent>
        </PopoverRoot>
    )
}

export default Popover

const StyledContent = styled(PopoverPrimitive.Content, {
    base: {
        borderRadius: 4,
        padding: 20,
        width: "max-content",
        zIndex: 400,
        display: "flex",
        flexDirection: "column",
        backgroundColor: "$gray1",
        boxShadow: "hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px",
        "@media (prefers-reduced-motion: no-preference)": {
            animationDuration: "400ms",
            animationTimingFunction: "cubic-bezier(0.16, 1, 0.3, 1)",
            willChange: "transform, opacity",
            "&[data-state=\"open\"]": {
                "&[data-side=\"top\"]": {
                    animationName: "slideDownAndFade"
                },
                "&[data-side=\"right\"]": {
                    animationName: "slideLeftAndFade"
                },
                "&[data-side=\"bottom\"]": {
                    animationName: "slideUpAndFade"
                },
                "&[data-side=\"left\"]": {
                    animationName: "slideRightAndFade"
                }
            }
        },
        "&:focus": {
            boxShadow: "hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px, 0 0 0 2px colors.$primary7"
        }
    }
});

const StyledArrow = styled(PopoverPrimitive.Arrow, {
    base: {
        fill: "$loContrast"
    }
});

function Content({ children, ...props }: any) {
    return (
        <PopoverPrimitive.Portal>
            <StyledContent {...props}>
                {children}
                <StyledArrow />
            </StyledContent>
        </PopoverPrimitive.Portal>
    );
}

const StyledClose = styled(PopoverPrimitive.Close, {
    base: {
        all: "unset",
        fontFamily: "inherit",
        borderRadius: "100%",
        height: 25,
        width: 25,
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        color: "$primary11",
        position: "absolute",
        top: 5,
        right: 5,
        "&:hover": {
            backgroundColor: "$primary4"
        },
        "&:focus": {
            boxShadow: "0 0 0 2px colors.$primary7"
        }
    }
});

// Exports
export const PopoverRoot = PopoverPrimitive.Root;
export const PopoverTrigger = styled(PopoverPrimitive.Trigger, {
    base: {}
});
export const PopoverContent = Content;
export const PopoverClose = StyledClose;