'use client'
import { flatten, isNumber, range, xor } from "lodash";
import { useRouter } from "next/navigation";
import {
    Category,
    Section,
    CategorySchema,
    SectionSchema,
    TodoGroupSchema,
} from "src/types";
import useActiveQuery from "./main/category/useActiveQuery";
import { TodoGroup } from "src/types";
import {
    useEditCategoryUnitMutation,
    useInactivateCategoryUnitMutation,
    usePutCategoryUnitMutation,
    useRemoveCategoryUnitMutation,
} from "@/redux/features/api/apiSlice";
import { getSectionId } from "@/utils/category";
import { getInfoFromCategoryPath } from "@/utils/categories";
import { useParams } from "next/navigation";
import { pathToCategoriesGlobal, getCategoryNumGlobal } from "@/utils/categories";

export default function useCategories(path?: string) {
    const router = useRouter();
    const params = (useParams() || {}) as { category?: string[] }

    const category =
        path?.split("/") || params.category;
    // const { category } = router.query as { category: string[] | undefined }
    const { categories: categoryUnits, url, data } = useActiveQuery();
    const [addCategoryUnit] = usePutCategoryUnitMutation();
    const [removeCategoryUnitFinal] = useRemoveCategoryUnitMutation();
    const [inactivateCategoryUnitFinal] = useInactivateCategoryUnitMutation();
    const [editCategoryUnitFinal] = useEditCategoryUnitMutation();

    const currentCategoryUnits =
        category && pathToCategories(category as string[]);
    const currentSection =
        currentCategoryUnits &&
        (currentCategoryUnits?.length > 0
            ? currentCategoryUnits[0]
            : undefined);
    const currentCategory =
        currentCategoryUnits &&
        (currentCategoryUnits?.length > 1
            ? currentCategoryUnits[1]
            : undefined);
    const currentGroup =
        currentCategoryUnits &&
        (currentCategoryUnits?.length > 2
            ? currentCategoryUnits[2]
            : undefined);
    const currentGroups =
        currentCategory && categoryUnits?.groups[currentCategory.id];

    // console.log('path', path)
    // console.log("currentGroup", currentGroup)

    function getFreeNums(sectionId: string) {
        // get next free color num
        const categories = categoryUnits?.categories[sectionId];
        const allowed = range(1, 9);
        if (!categories || categories.length === 0) return [...allowed]; // random(1, 8)
        const freeNums = xor(
            allowed,
            categories.map((x) => x.colorNum)
        );
        if (freeNums.length === 0) return [...allowed];
        freeNums.sort();
        return freeNums;
    }

    /** helpers */
    function pathToCategories(
        path: string | string[]
    ):
        | [Section]
        | [Section, Category]
        | [Section, Category, TodoGroup]
        | undefined {
        return pathToCategoriesGlobal(path, categoryUnits);
    }

    const pathToNamesArray = (path: string | string[]) => {
        if (!categoryUnits) return undefined;
        return pathToCategories(path)?.map((x) => x.name);
    };

    const getCategoryName = (path: string) => {
        const names = pathToNamesArray(path);
        return names?.at(1);
    };

    const isValidPath = (path: string | string[]) => {
        if (!categoryUnits) return;
        try {
            pathToNamesArray(path);
            return true;
        } catch (e) {
            return false;
        }
    };

    const getCategoryUnit = (path: string | string[]) => {
        if (!categoryUnits) return;
        if (typeof path === "string") path = path.split("/");
        return pathToCategories(path)?.at(-1);
    };

    const getCategoryNum = (
        path: string | string[],
        attribute?: string
    ): number | null => {
        return getCategoryNumGlobal(path, categoryUnits);
    };

    const getNextFreeNum = (path: string) => {
        const freeNums = getFreeNums(path);
        return freeNums[0];
    };

    /**
     * db- interactions
     */
    // // TODO: allow color selection from form?
    const putCategoryUnit = async (
        properties: Partial<Category> | Partial<Section>,
        path?: string
    ) => {
        const { isSection, isCategory, isGroup } =
            getInfoFromCategoryPath(path);
        if (isCategory && !isNumber(properties.colorNum)) {
            properties.colorNum = getNextFreeNum(path!);
        }

        let initSchema = isSection
            ? SectionSchema
            : isCategory
            ? CategorySchema
            : TodoGroupSchema;
        const schema = initSchema.partial(); // Before it was .partial({active: true})
        const dbProperties = schema.strip().parse(properties);
        addCategoryUnit({ path, dbProperties });
    };

    const editCategoryUnit = async (
        path: string,
        properties: { [x: string]: any }
    ) => {
        // TODO: remove this function, replace with just the redux hook
        editCategoryUnitFinal({ path, properties });
    };

    const inactivateCategoryUnit = async (
        path: string,
        activity: number = 0
    ) => {
        inactivateCategoryUnitFinal({ path, activity });
        if (category && (category as string[]).join("/").startsWith(path))
            router.push("/list");
    };

    const removeCategoryUnit = async (path: string) => {
        const currentCategoryUnitPathName =
            pathToNamesArray(path)?.at(-1) || path.split("/").at(-1);
        const sectionCategoryConfirmMessage = `Are you sure you want to delete everything inside '${currentCategoryUnitPathName}'? \nIf you don't want to lose everything inside, archive category instead.`;
        const groupConfirmMessage = `Confirm delete? Everything inside ${currentCategoryUnitPathName} will be deleted.`;
        const isGroup = path.split("/").length === 3;
        const confirm = window.confirm(
            isGroup ? groupConfirmMessage : sectionCategoryConfirmMessage
        );
        if (!confirm) return;

        removeCategoryUnitFinal({ path });
        if (category && (category as string[]).join("/").startsWith(path))
            router.push("/list");
    };

    return {
        pathToCategories,
        pathToNamesArray,
        isValidPath,
        getCategoryUnit,
        categoryUnits,
        sections: categoryUnits?.sections,
        categoriesDict: categoryUnits?.categories,
        getCategoryNum,
        categoryList:
            categoryUnits?.categories &&
            (flatten(Object.values(categoryUnits?.categories)) as Category[]),
        groups: categoryUnits?.groups,
        removeCategoryUnit,
        inactivateCategoryUnit,
        editCategoryUnit,
        putCategoryUnit,
        getFreeNums,
        isLoading: !categoryUnits,
        getSectionId,
        currentCategory,
        currentSection,
        currentGroups,
        currentGroup,
        getCategoryName,
        data,
    };
}
