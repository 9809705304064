import React from 'react'
import styles from './arrow.module.css'
import { cva, cx } from 'styled-system/css'

const arrow = cva({
    base: {
        zIndex: 100,
    },
    variants: {
        direction: {
            left: {
                right: -75,
                left: 'auto',
                rotate: '180deg',
                scale: 0.5
            },
            right: {
                left: -75,
            }
        }
    }
})

const Arrow = ({ style, direction, className }: { style?: any, className?: string, direction?: 'left' | 'right' }) => {
    return (
        <div className={cx(styles.icon, arrow({ direction, }), className)} style={style}>
            <div className={styles.arrow}></div>
        </div>
    )
}

export default Arrow