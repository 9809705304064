import { usePathname, useSearchParams, useRouter } from 'next/navigation'

export const useSearchParamActions = () => {
  const router = useRouter()
  const searchParams = useSearchParams()
  const pathname = usePathname()

  const getParams = () => {
    return new URLSearchParams(searchParams?.toString())
  }

  const shallowReplace = (params?: unknown) => {
    params = `${params}`
    if (params === searchParams?.toString()) return
    let path = `${pathname}`
    if (params) {
      path += `?${params}`
    }
    router.replace(path, { scroll: false })
  }

  const setSearchParam = (key: string, value: string) => {
    if (!pathname) return
    const params = getParams()
    params.set(key, value)
    shallowReplace(params)
    return params
  }

  const setSearchParamArray = (key: string, value: string[], params?: URLSearchParams) => {
    if (!params) params = getParams()
    if (value.length === 0) {
      params.delete(key)
    } else {
      // TODO: should I use append or ,
      value.forEach((v) => params.append(key, v))
    }
    return params
  }

  const setSearchParams = (newQuery: Record<string, string | string[]>) => {
    const newParams = getParams()
    for (const key in newQuery) {
      const value = newQuery[key]
      if (Array.isArray(value)) {
        setSearchParamArray(key, value, newParams)
      } else {
        newParams.set(key, value)
      }
    }
    shallowReplace(newParams)
  }

  return {
    setSearchParam,
    router,
    searchParams,
    pathname,
    shallowReplace,
    setSearchParams,
  }
}

export type SearchParamActions = ReturnType<typeof useSearchParamActions>